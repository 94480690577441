import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { Login } from './Components/Login/Login';
import ProtectedRoutes from './Components/ProtectedRoutes';
import Dashboard from './Components/Dashboard/Dashboard/Dashboard';
import Home from './Components/Dashboard/Home/Home';
import Register from './Components/Register/Register';
import { ResetPassword } from './Components/ResetPassword/ResetPassword';
import Train from "./Components/Dashboard/Train/Train";
import Freightcar from "./Components/Dashboard/Freightcar/Freightcar";
import ChangePassword from "./Components/Dashboard/ChangePassword/ChangePassword";
import Entry from "./Components/Entry/Entry";

function App() {
  return (
    <Routes>
        <Route path="/" element={<Entry />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route element={<ProtectedRoutes />}>
        <Route path="dashboard" element={<Dashboard />} >
            <Route path='home' element={<Home />} />
            <Route path="trains/:trainId" element={<Train />} />
            <Route path="freightcars/:freightcarId" element={<Freightcar />} />
            <Route path="changepassword" element={<ChangePassword />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
