import Axios from "axios";
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { setToken } from '../../Utils/Utils';
import './ResetPassword.scss';
import backgroundImage from './bckg.jpg'
import Logo from './logo.svg';

export function ResetPassword() {

    const navigate = useNavigate();

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    });

    const [wrongCredentials, setWrongCredentials] = useState(false);

    const changeHandler = (e) => {
        setLoginForm((prevState) => {
            return {...prevState, [e.target.name]: e.target.value}
        })
    }

    const requestLogin = () => {
        Axios.post(process.env.REACT_APP_API_URL + '/login', {
            email: loginForm.email,
            password: loginForm.password
        })
        .then(res => {
            if(res.status === 200) {
                console.log(res.data);
                setToken(res.data.token);
                navigate('/dashboard/home');
            }
        })
        .catch(res => {
            if(res.response.status === 401) {
                console.log('unauthorized');
            } else if (res.response.status === 500) {
                console.log('system error');
            }
        })
    }

    return (
        <div className="login-wrapper" style={{
            backgroundImage: `url(${backgroundImage})`
        }}>
            <div className="login">
                <div className="header">
                    <img src={Logo}></img>
                </div>
                
                <div className="email d-flex flex-column">
                    <span>E-mail address:</span>
                    <input type="text" name="email" onChange={changeHandler}/>
                </div>
                <div className="lost-wrapper d-flex flex-row justify-content-between align-items-center">
                    <span></span>
                    <button className="login-btn" onClick={requestLogin}>Reset</button>
                </div>
                
            </div>
        </div>
    )
}