import Axios from 'axios';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate();

    const [ registrationForm, setRegistrationForm ] = useState({
        email: '',
        password: ''
    })

    const formHandler = (e) => {
        setRegistrationForm((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        console.log('submit');

        if(registrationForm.email === '' || registrationForm.password === '') {
            console.log('empty');
            return;
        }

        Axios.post('http://localhost:8080/user', {
            email: registrationForm.email,
            password: registrationForm.password
        }).then(res => {
            if(res.status === 200) {
                navigate('/login');
            }
        })
    }

    return (
        <form onSubmit={formSubmit}>
            <div className="form-group">
                <label>Email address</label>
                <input type="email" name="email" className="form-control" onChange={formHandler} />
            </div>
            <div className="form-group">
                <label>Password</label>
                <input type="password" className="form-control" name="password" onChange={formHandler} />
            </div>
            <button type="submit" className="btn btn-primary">Register</button>
        </form>
    )
}

export default Register;