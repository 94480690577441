import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import {  isLoggedIn, logout } from '../../../Utils/Utils';
import MenuIcon from './menu-icon.svg';
import MenuLogo from './menu-logo.svg';

import './Dashboard.scss';
import {Dropdown} from "react-bootstrap";

const menuItems = [
    {
        title: 'Vlaki',
        url: '/dashboard/home'
    },
]

const Dashboard = () => {
    const navigate = useNavigate();

    const logoutFunction = () => {
        logout();
        navigate('/login');
    }

    const buildMenu = () => {
        return menuItems.map((item) => {
            return (
                <li className="d-flex flex-row align-items-center">
                    <img className="me-2 ms-2" src={MenuIcon} alt=""/><Link to={item.url}>{item.title}</Link>
                </li>
            )
        })
    }

    return (
        <>
            <div className="dashboard">
                <div className="header">
                    <div className="top-navigation d-flex flex-row align-items-center justify-content-between">
                        <img onClick={() => {navigate('/dashboard/home')}} className="menu-logo" src={MenuLogo} alt=""/>
                        <ul>
                        </ul>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-user">
                                {JSON.parse(localStorage.getItem('token')).name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {navigate('/dashboard/changepassword')}}>Nastavitve</Dropdown.Item>
                                <Dropdown.Item onClick={logoutFunction}>Odjava</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="side-navigation">
                    <ul>
                        {buildMenu()}
                    </ul>
                    {/* <ul>
                    <li>
                        <Link to="/dashboard/home" >Home</Link>
                    </li>
                    <li>
                        <Link to="/dashboard/listcards" >List cards</Link>
                    </li>
                    <li>
                        <Link to="/dashboard/addcard" >Add new card</Link>
                    </li>
                    <li>
                        <a href="#" onClick={logoutFunction}>Logout</a>
                    </li>
                </ul> */}
                </div>
                <div className="content">
                    <Outlet />
                </div>
            </div>
            
        </>
    )
}

export default Dashboard;