import Table from 'bootstrap';
import Axios from "axios";
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import './Home.scss';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import ReactPaginate from 'react-paginate';

const Home = () => {
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [trains, setTrains] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const isFirstRender = useRef(true);

    const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + 15;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = trains.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(trains.length / 15);

    useEffect(()=>{
        console.log(localStorage.getItem('token'))
        Axios.get(process.env.REACT_APP_API_URL + '/trains', {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then(res => {
                setTrains(res.data);
            })
    },[]);

    useEffect(()=>{
        if(isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        requestTrains();
    }, [startDate, endDate]);

    const changeStartDate = (date) => {
        console.log(date);
        setStartDate(date);
    }

    const changeEndDate = (date) => {
        console.log(date);
        setEndDate(date);
    }

    const requestTrains = () => {
        let sDate = dayjs(startDate).format('YYYY-MM-DD');
        let eDate = dayjs(endDate).format('YYYY-MM-DD');

        Axios.get(process.env.REACT_APP_API_URL + '/trains', {
            params: {
                startDate: sDate,
                endDate: eDate
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(res => {
            setTrains(res.data);
        })
    }

    const renderTrains = () => {
        return trains.map((train) => {
            if(train.oznaka_koncnega_naslovnika !== 'AKOM') {
                return (
                    <tr>
                        <td>{train.oznaka_posiljatelja}</td>
                        <td>{train.oznaka_naslovnika}</td>
                        <td>{train.ime_datoteke}</td>
                        <td>{train.oznaka_sporocila}</td>
                        <td>{train.datum_priprave}</td>
                        <td>{train.ura_priprave}</td>
                        <td>{train.parametri_v_odvisnosti}</td>
                        <td>{train.oznaka_koncnega_naslovnika}</td>
                        <td><Link to={'/dashboard/trains/' + train.id}>Preglej več</Link></td>
                    </tr>
                )
            }
            
        })
    }

    const handlePageClick = (data) => {
        console.log(data);
    }
    return (
        <div className="trains">
            <h1 className="mb-4">Vlaki</h1>
            <div className="filter-wrapper">
                <h2>Filter vlakov:</h2>
                <div className="filter mb-4 d-flex flex-row align-items-center">
                    <span className="me-1">Od: </span><DatePicker className="datePicker me-4" dateFormat="dd.MM.yyyy" selected={startDate} onChange={(date) => changeStartDate(date)} />
                    <span className="me-1">Do: </span> <DatePicker className="datePicker" dateFormat="dd.MM.yyyy" selected={endDate} onChange={(date) => changeEndDate(date)} />
                </div>
            </div>
            <table className="table table-striped custom-table">
                <thead>
                <tr>
                    <th scope="col">Oznaka pošiljatelja</th>
                    <th scope="col">Oznaka naslovnika</th>
                    <th scope="col">Ime datoteke</th>
                    <th scope="col">Oznaka sporočila</th>
                    <th scope="col">Datum priprave</th>
                    <th scope="col">Ura priprave</th>
                    <th scope="col">Parametri v odvisnosti</th>
                    <th scope="col">Oznaka končnega naslovnika</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {renderTrains()}
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />

                </tbody>
            </table>
        </div>

    )
}

export default Home;