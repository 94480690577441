import {Link, useParams} from "react-router-dom";
import Axios from "axios";
import {useEffect, useState} from "react";
import './Freightcar.scss';

const Freightcar = () => {
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [train, setTrain] = useState({});
    const [trainLoaded, setTrainLoaded] = useState(false);
    const params = useParams();

    useEffect(()=>{
        Axios.get(process.env.REACT_APP_API_URL + '/freightcars/' + params.freightcarId, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then(res => {
                setTrain(res.data);
                setTrainLoaded(true);
                console.log(res.data.train)
            })
    },[]);

    const renderFreightcar = () => {
        return (
            <div className="train-wrapper">
                <div className="train-info">
                    <h1>Informacije</h1>
                    <table>
                        <tbody>
                        <tr>
                            <td>Oznaka pošiljatelja sporočila</td>
                            <td>{train.train.oznaka_posiljatelja}</td>
                        </tr>
                        <tr>
                            <td>Oznaka naslovnika</td>
                            <td>{train.train.oznaka_naslovnika}</td>
                        </tr>
                        <tr>
                            <td>Ime datoteke, ki vsebuje sporočilo</td>
                            <td>{train.train.ime_datoteke}</td>
                        </tr>
                        <tr>
                            <td>Oznaka sporočila</td>
                            <td>{train.train.oznaka_sporocila}</td>
                        </tr>
                        <tr>
                            <td>Datum priprave sporočila</td>
                            <td>{train.train.datum_priprave}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="freightcar-wrapper">
                    <table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>Ime segmenta</td>
                            <td>{train.freightcar.ime_segmenta}</td>
                        </tr>
                        <tr>
                            <td>Datum plana</td>
                            <td>{train.freightcar.datum_plana}</td>
                        </tr>
                        <tr>
                            <td>Smer predaje vagonov</td>
                            <td>{train.freightcar.smer_predaje_vagonov}</td>
                        </tr>
                        <tr>
                            <td>Zaporedna številka pozicije v planu</td>
                            <td>{train.freightcar.zaporedna_stevilka_pozicije_v_planu}</td>
                        </tr>
                        <tr>
                            <td>Planirana ura predaje</td>
                            <td>{train.freightcar.planirana_ura_predaje}</td>
                        </tr>
                        <tr>
                            <td>Predvidena ura odvoza</td>
                            <td>{train.freightcar.predvidena_ura_odvoza}</td>
                        </tr>
                        <tr>
                            <td>Manipulacija</td>
                            <td>{train.freightcar.manipulacija}</td>
                        </tr>
                        <tr>
                            <td>SZ šifra naročnika</td>
                            <td>{train.freightcar.sz_sifra_narocnika}</td>
                        </tr>
                        <tr>
                            <td>Vrsta voz iz naročniške knjige</td>
                            <td>{train.freightcar.vrsta_voz_iz_narocniske_knjige}</td>
                        </tr>
                        <tr>
                            <td>NHM koda blaga</td>
                            <td>{train.freightcar.nhm_koda_blaga}</td>
                        </tr>
                        <tr>
                            <td>Primopredajno mesto</td>
                            <td>{train.freightcar.primopredajno_mesto}</td>
                        </tr>
                        <tr>
                            <td>Oznaka tira</td>
                            <td>{train.freightcar.oznaka_tira}</td>
                        </tr>
                        <tr>
                            <td>Lokacija za dostavo vagona</td>
                            <td>{train.freightcar.lokacija_za_dostavo_vagona}</td>
                        </tr>
                        <tr>
                            <td>Opombe</td>
                            <td>{train.freightcar.opombe}</td>
                        </tr>
                        <tr>
                            <td>Povezava na naročniško knjigo</td>
                            <td>{train.freightcar.povezava_na_narocnisko_knjigo}</td>
                        </tr>
                        <tr>
                            <td>Status plana</td>
                            <td>{train.freightcar.status_plana}</td>
                        </tr>
                        <tr>
                            <td>Zaporedna številka prenosa</td>
                            <td>{train.freightcar.zaporedna_stevilka_prenosa}</td>
                        </tr>
                        <tr>
                            <td>Šifra opombe</td>
                            <td>{train.freightcar.sifra_opombe}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    return (
        <>
            {trainLoaded ?
            renderFreightcar()
            : ''}
        </>
    )
}

export default Freightcar;