import {Link, useParams} from "react-router-dom";
import Axios from "axios";
import {useEffect, useState} from "react";
import './Train.scss';

const Train = () => {
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [train, setTrain] = useState({});
    const [trainLoaded, setTrainLoaded] = useState(false);
    const params = useParams();

    useEffect(()=>{
        Axios.get(process.env.REACT_APP_API_URL + '/trains/' + params.trainId, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then(res => {
                setTrain(res.data);
                setTrainLoaded(true);
                console.log(train)
            })
    },[]);

    const renderFreightcars = () => {
        return train.freightcars.map((freightcar) => {
            return (
                <tr>
                    <td>{freightcar.ime_segmenta}</td>
                    <td>{freightcar.datum_plana}</td>
                    <td>{freightcar.smer_predaje_vagonov}</td>
                    <td>{freightcar.zaporedna_stevilka_pozicije_v_planu}</td>
                    <td>{freightcar.zaporedna_stevilka_popravka_plana}</td>
                    <td>{freightcar.planirana_ura_predaje}</td>
                    <td>{freightcar.predvidena_ura_odvoza}</td>
                    <td>{freightcar.manipulacija}</td>
                    <td>{freightcar.sz_sifra_narocnika}</td>
                    <td><Link to={'/dashboard/freightcars/' + freightcar.id}>Preglej več</Link></td>
                </tr>
            )
        })
    }
    return (
        <div className="train-wrapper">
            <div className="train-info">
                <h1>Informacije</h1>
                <table>
                    <tbody>
                        <tr>
                            <td>Oznaka pošiljatelja sporočila</td>
                            <td>{train.oznaka_posiljatelja}</td>
                        </tr>
                        <tr>
                            <td>Oznaka naslovnika</td>
                            <td>{train.oznaka_naslovnika}</td>
                        </tr>
                        <tr>
                            <td>Ime datoteke, ki vsebuje sporočilo</td>
                            <td>{train.ime_datoteke}</td>
                        </tr>
                        <tr>
                            <td>Oznaka sporočila</td>
                            <td>{train.oznaka_sporocila}</td>
                        </tr>
                        <tr>
                            <td>Datum priprave sporočila</td>
                            <td>{train.datum_priprave}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="freightcars-wrapper">
                <table className="table table-striped custom-table">
                    <thead>
                        <tr>
                            <th scope="col">Ime segmenta</th>
                            <th scope="col">Datum plana</th>
                            <th scope="col">Smer predaje vagonov</th>
                            <th scope="col">Zaporedna številka pozicije v planu</th>
                            <th scope="col">Zaporedna številka popravka plana</th>
                            <th scope="col">Planirana ura predaje</th>
                            <th scope="col">Predvidena ura odvoza</th>
                            <th scope="col">Manipulacija</th>
                            <th scope="col">SŽ šifra naročnika</th>
                            <th scope="col">lala</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trainLoaded ? renderFreightcars() : ''}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Train;