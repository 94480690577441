import Axios from "axios";
import { useState } from "react"
import {Link, Navigate, Outlet, useNavigate} from "react-router-dom";
import {isLoggedIn, setToken} from '../../Utils/Utils';
import './Login.scss';
import backgroundImage from './bckg.jpg'
import Logo from './logo.svg';
import { Alert } from "react-bootstrap";
import Store from 'store';

export function Login() {
    const navigate = useNavigate();

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    });

    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [wrongCredentialsMessage, setWrongCredentialsMessage] = useState('');

    const changeHandler = (e) => {
        setLoginForm((prevState) => {
            return {...prevState, [e.target.name]: e.target.value}
        })
    }

    const requestLogin = () => {
        Axios.post(process.env.REACT_APP_API_URL + '/login', {
            email: loginForm.email,
            password: loginForm.password
        })
        .then(res => {
            if(res.status === 200) {
                setWrongCredentials(false);
                setWrongCredentialsMessage(false);
                console.log(res.data);
                Store.set('token', {
                    name: res.data.name,
                    email: res.data.email,
                    token: res.data.token
                });
                navigate('/dashboard/home');
            }
        })
        .catch(res => {
            if(res.response.status === 401) {
                console.log('unauthorized');
                setWrongCredentialsMessage('Napačno uporavniško ime ali geslo');
                setWrongCredentials(true);
            } else if (res.response.status === 422) {
                console.log('422');
                setWrongCredentialsMessage('Prosimo izpolnite vsa polja');
                setWrongCredentials(true);
            } else if (res.response.status === 500) {
                console.log('system error');
            }
        })
    }

    return (
        <div className="login-wrapper" style={{
            backgroundImage: `url(${backgroundImage})`
        }}>
            <div className="login">
                <div className="header">
                    <img src={Logo}></img>
                </div>
                
                <div className="email d-flex flex-column">
                    <span>Username or e-mail address:</span>
                    <input type="text" name="email" onChange={changeHandler}/>
                </div>
                <div className="password d-flex flex-column mt-3">
                    <span>Password:</span>
                    <input type="password" name="password" onChange={changeHandler} />
                </div>
                {wrongCredentials ? 
                <Alert className="mt-3" key={"danger"} variant={"danger"}>
                    {wrongCredentialsMessage}
                </Alert> : ''}
                <div className="lost-wrapper d-flex flex-row justify-content-between align-items-center">
                    <Link className="lost" to="/resetpassword">Pozabil sem geslo</Link>
                    <button className="login-btn" onClick={requestLogin}>Login</button>
                </div>
                
            </div>
        </div>
    )
}