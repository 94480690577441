import {useState} from "react";
import './ChangePassword.scss';
import {Button, Form} from "react-bootstrap";
import { Alert } from "react-bootstrap";
import Axios from "axios";

const ChangePassword = () => {
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [form, setForm] = useState({
        password: '',
        password_confirmation: ''
    });
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const fieldChange = (e) => {
        setForm((prevState) => {
            return {...prevState, [e.target.name]: e.target.value}
        })
    }

    const changePasswordFunc = (e) => {
        e.preventDefault();
        let password = form.password;
        let password_confirmation = form.password_confirmation;

        if(password !== password_confirmation) {
            setIsError(true);
            setMessage('Gesli se ne ujemata');
        } else {
            console.log('reset');
            Axios.post(process.env.REACT_APP_API_URL + '/changepassword', {
                password: password,
                password_confirmation: password_confirmation
            }, {
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(res => {
                if(res.status === 200) {
                    setMessage('Geslo je bilo uspešno spremenjeno')
                    setIsError(false);
                    setIsSuccess(true);
                }

            })
        }
        console.log('dela');
    }

    return (
        <div className="changepassword-wrapper">
            <h1>Spememba gesla</h1>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Geslo</Form.Label>
                                <Form.Control onChange={fieldChange} name="password" type="password" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Ponovite geslo</Form.Label>
                                <Form.Control onChange={fieldChange} name="password_confirmation" type="password" />
                            </Form.Group>
                            <Button onClick={changePasswordFunc} style={{backgroundColor: '#0F276F', borderColor: '#0F276F'}} type="submit">
                                Spremeni
                            </Button>
                            {isError ? <Alert className="mt-3" key="danger" variant="danger">
                                {message}
                            </Alert> : ''}

                            {isSuccess ? <Alert className="mt-3" key="success" variant="success">
                                {message}
                            </Alert> : ''}
                        </Form>
                    </div>
                </div>
        </div>
    )
}

export default ChangePassword;