import { Navigate, Outlet } from "react-router-dom"
import { isLoggedIn } from "../Utils/Utils";

const ProtectedRoutes = () => {
    console.log(isLoggedIn());

    return (
        isLoggedIn() ? <Outlet /> : <Navigate to="/login" />
    )
}

export default ProtectedRoutes;