import { Navigate, Outlet } from "react-router-dom"
import { isLoggedIn } from "../../Utils/Utils";

const Entry = () => {
    console.log(isLoggedIn());

    return (
        isLoggedIn() ? <Navigate to="/dashboard/home" /> : <Navigate to="/login" />
    )
    return (
        <p>entry</p>
    )
}

export default Entry;