export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const logout = () => {
    localStorage.removeItem('token');
}

export const isLoggedIn = () => {
    if(localStorage.getItem('token') !== null) {
        return true;
    } else {
        return false;
    }
}